const state = {
  menus: [
    {
      id: 0,
      slug: "entrees-et-salades",
      title: "menus.salads.title",
      logo: "menus/salads",
      inverted: false,
      size: 1,
      cards: [
        {
          type: "table",
          title: "menus.salads.0.title",
          head: {
            col1: "menus.salads.0.hc1",
            col2: "menus.salads.0.hc2",
            col3: "menus.salads.0.hc3"
          },
          rows: [
            {
              col1: "menus.salads.0.r0c1",
              col2: "14.50€",
              col3: "19.50€",
              desc: "menus.salads.0.r0desc"
            },
            {
              col1: "menus.salads.0.r1c1",
              col2: "14.50€",
              col3: "19.50€",
              desc: "menus.salads.0.r1desc"
            },
            {
              col1: "menus.salads.0.r2c1",
              col2: "14.50€",
              col3: "19.50€",
              desc: "menus.salads.0.r2desc"
            }
          ]
        },
        {
          type: "table",
          title: "menus.salads.1.title",
          rows: [
            {
              col1: "menus.salads.1.r0c1",
              col2: "12€ les 6, 17€ les 12"
            },
            {
              col1: "menus.salads.1.r1c1",
              col2: "14€",
              desc: "menus.salads.1.r1desc"
            },
            {
              col1: "menus.salads.1.r2c1",
              col2: "17€",
              desc: "menus.salads.1.r2desc"
            },
            {
              col1: "menus.salads.1.r3c1",
              col2: "22€",
              desc: "menus.salads.1.r3desc"
            },
            {
              col1: "menus.salads.1.r4c1",
              col2: "14€",
              desc: "menus.salads.1.r4desc"
            }
          ]
        }
      ]
    },
    {
      id: 1,
      slug: "viandes-poissons-et-burgers",
      title: "menus.meat.title",
      logo: "menus/meat",
      inverted: false,
      size: 1,
      cards: [
        {
          type: "table",
          title: "menus.meat.0.title",
          comments: "menus.meat.0.comments",
          rows: [
            {
              col1: "menus.meat.0.r0c1",
              col2: "23.50€",
              desc: "menus.meat.0.r0desc"
            },
            {
              col1: "menus.meat.0.r1c1",
              col2: "27.50€",
              desc: "menus.meat.0.r1desc"
            },
            {
              col1: "menus.meat.0.r2c1",
              col2: "27.50€",
              desc: "menus.meat.0.r2desc"
            },
            {
              col1: "menus.meat.0.r3c1",
              col2: "24.50€",
              desc: "menus.meat.0.r3desc"
            },
            {
              col1: "menus.meat.0.r4c1",
              col2: "36.50€",
              desc: "menus.meat.0.r4desc"
            },
            {
              col1: "menus.meat.0.r5c1",
              col2: "22.50€",
              desc: "menus.meat.0.r5desc"
            }
          ]
        },
        {
          type: "table",
          title: "menus.meat.1.title",
          comments: "menus.meat.1.comments",
          rows: [
            {
              col1: "menus.meat.1.r0c1",
              col2: "24.50€",
              desc: "menus.meat.1.r0desc"
            }
          ]
        },
        {
          type: "table",
          title: "menus.meat.2.title",
          comments: "menus.meat.2.comments",
          rows: [
            {
              col1: "menus.meat.2.r0c1",
              col2: "20.50€",
              desc: "menus.meat.2.r0desc"
            },
            {
              col1: "menus.meat.2.r1c1",
              col2: "20.50€",
              desc: "menus.meat.2.r1desc"
            },
            {
              col1: "menus.meat.2.r2c1",
              col2: "19.50€",
              desc: "menus.meat.2.r2desc"
            },
            {
              col1: "menus.meat.2.r3c1",
              col2: "20.50€",
              desc: "menus.meat.2.r3desc"
            }
          ]
        }
      ]
    },
    {
      id: 2,
      slug: "ravioles-et-specialites-montagnardes",
      title: "menus.ravioles.title",
      logo: "menus/ravioles",
      inverted: false,
      size: 1,
      cards: [
        {
          type: "table",
          title: "menus.ravioles.0.title",
          rows: [
            {
              col1: "menus.ravioles.0.r0c1",
              col2: "18.50€",
              desc: "menus.ravioles.0.r0desc"
            },
            {
              col1: "menus.ravioles.0.r1c1",
              col2: "18.50€",
              desc: "menus.ravioles.0.r1desc"
            },
            {
              col1: "menus.ravioles.0.r2c1",
              col2: "19.50€",
              desc: "menus.ravioles.0.r2desc"
            },
            {
              col1: "menus.ravioles.0.r3c1",
              col2: "19.50€",
              desc: "menus.ravioles.0.r3desc"
            },
            {
              col1: "menus.ravioles.0.r4c1",
              col2: "18.50€",
              desc: "menus.ravioles.0.r4desc"
            }
          ]
        },
        {
          type: "table",
          title: "menus.ravioles.1.title",
          rows: [
            {
              col1: "menus.ravioles.1.r0c1",
              col2: "23.50€",
              desc: "menus.ravioles.1.r0desc"
            },
            {
              col1: "menus.ravioles.1.r1c1",
              col2: "23.50€",
              desc: "menus.ravioles.1.r1desc"
            },
            {
              col1: "menus.ravioles.1.r2c1",
              col2: "23.50€",
              desc: "menus.ravioles.1.r2desc"
            },
            {
              col1: "menus.ravioles.1.r3c1",
              col2: "27.50€",
              desc: "menus.ravioles.1.r3desc"
            }
          ]
        },
        {
          type: "table",
          title: "menus.ravioles.2.title",
          comments: "menus.ravioles.2.comments",
          rows: [
            {
              col1: "menus.ravioles.2.r0c1",
              col2: "26.50€/pers.",
              desc: "menus.ravioles.2.r0desc"
            },
            {
              col1: "menus.ravioles.2.r1c1",
              col2: "27.50€/pers.",
              desc: "menus.ravioles.2.r1desc"
            },
            {
              col1: "menus.ravioles.2.r2c1",
              col2: "23.50€/pers.",
              desc: "menus.ravioles.2.r2desc"
            },
            {
              col1: "menus.ravioles.2.r3c1",
              col2: "26.50€/pers.",
              desc: "menus.ravioles.2.r3desc"
            },
            {
              col1: "menus.ravioles.2.r4c1",
              col2: "26.50€/pers.",
              desc: "menus.ravioles.2.r4desc"
            },
            {
              col1: "menus.ravioles.2.r5c1",
              col2: "27.50€/pers.",
              desc: "menus.ravioles.2.r5desc"
            },
            {
              col1: "menus.ravioles.2.r6c1",
              col2: "9€"
            },
            {
              col1: "menus.ravioles.2.r7c1",
              col2: "7€"
            }
          ]
        }
      ]
    },
    {
      id: 3,
      slug: "fromages-et-desserts",
      title: "menus.cheeses.title",
      logo: "menus/cheeses",
      inverted: false,
      size: 1,
      cards: [
        {
          type: "table",
          title: "menus.cheeses.0.title",
          rows: [
            {
              col1: "menus.cheeses.0.r0c1",
              col2: "8.50€",
              desc: "menus.cheeses.0.r0desc"
            },
            {
              col1: "menus.cheeses.0.r1c1",
              col2: "7.50€",
              desc: "menus.cheeses.0.r1desc"
            },
            {
              col1: "menus.cheeses.0.r2c1",
              col2: "8.50€"            
            },
            {
              col1: "menus.cheeses.0.r3c1",
              col2: "8.50€",
              desc: "menus.cheeses.0.r3desc"
            },
            {
              col1: "menus.cheeses.0.r4c1",
              col2: "9€",
              desc: "menus.cheeses.0.r4desc"
            },
            {
              col1: "menus.cheeses.0.r5c1",
              col2: "9.50€"
            },
            {
              col1: "menus.cheeses.0.r6c1",
              col2: "9.50€"
            },
            {
              col1: "menus.cheeses.0.r7c1",
              col2: "9.50€"
            },
            {
              col1: "menus.cheeses.0.r8c1",
              col2: "9.50€"
            },
            {
              col1: "menus.cheeses.0.r9c1",
              col2: "11€"
            },
          {
            col1: "menus.cheeses.0.r10c1",
            col2: "3€"
          }
          ]
        }
      ]
    },
    {
      id: 4,
      slug: "crepes-et-gaufres",
      title: "menus.waffles.title",
      logo: "menus/waffles",
      inverted: false,
      size: 1,
      cards: [
        {
          type: "table",
          title: "menus.waffles.0.title",
          head: {
            col1: "menus.waffles.0.hc1",
            col2: "menus.waffles.0.hc2",
            col3: "menus.waffles.0.hc3"
          },
          rows: [
            {
              col1: "menus.waffles.0.r0c1",
              col2: "9€",
              col3: "10€",
              desc: "menus.waffles.0.r0desc"
            },
            {
              col1: "menus.waffles.0.r2c1",
              col2: "9€",
              col3: "10€",
              desc: "menus.waffles.0.r2desc"
            },
            {
              col1: "menus.waffles.0.r3c1",
              col2: "9€",
              col3: "10€",
              desc: "menus.waffles.0.r3desc"
            },
            {
              col1: "menus.waffles.0.r4c1",
              col2: "9€",
              col3: "10€",
              desc: "menus.waffles.0.r4desc"
            }
          ]
        },
        {
          type: "table",
          head: {
            col1: "menus.waffles.1.hc1",
            col2: "menus.waffles.1.hc2",
            col3: "menus.waffles.1.hc3"
          },
          rows: [
            {
              col1: "menus.waffles.1.r0c1",
              col2: "4€",
              col3: "4.50€"
            },
            {
              col1: "menus.waffles.1.r1c1",
              col2: "5€",
              col3: "5.50€"
            },
            {
              col1: "menus.waffles.1.r2c1",
              col2: "5.50€",
              col3: "6€"
            },
            {
              col1: "menus.waffles.1.r3c1",
              col2: "5€",
              col3: "5.50€"
            },
            {
              col1: "menus.waffles.1.r4c1",
              col2: "5.50€",
              col3: "6€"
            },
            {
              col1: "menus.waffles.1.r5c1",
              col2: "5.50€",
              col3: "6€"
            },
            {
              col1: "menus.waffles.1.r6c1",
              col2: "5€",
              col3: "5.50€"
            },
            {
              col1: "menus.waffles.1.r7c1",
              col2: "5€",
              col3: "5.50€"
            },
            {
              col1: "menus.waffles.1.r8c1",
              col2: "5€",
              col3: "5.50€"
            },
            {
              col1: "menus.waffles.1.r9c1",
              col2: "5€",
              col3: "5.50€"
            },
            {
              col1: "menus.waffles.1.r10c1",
              col2: "4.50€",
              col3: "-"
            },
            {
              col1: "menus.waffles.1.r11c1",
              col2: "4.50€",
              col3: "-"
            },
            {
              col1: "menus.waffles.1.r12c1",
              col2: "6€",
              col3: "-"
            }
          ]
        }
      ]
    },
    {
      id: 5,
      slug: "menus",
      title: "menus.menus.title",
      logo: "menus/menus",
      inverted: false,
      size: 1,
      cards: [
        {
          type: "text",
          title: "menus.menus.0.title",
          price: "13.50€",
          comments: "menus.menus.0.comments",
          text: ["menus.menus.0.t0", "menus.menus.0.t1", "menus.menus.0.t2"]
        },
        {
          type: "text",
          title: "menus.menus.1.title",
          price: "35€",
          text: ["menus.menus.1.t0", "menus.menus.1.t1", "menus.menus.1.t2"]
        },
        {
          type: "text",
          title: "menus.menus.2.title",
          price: "35€",
          text: ["menus.menus.2.t0", "menus.menus.2.t1", "menus.menus.2.t2"]
        }
      ]
    }
  ],
  drinks: [
    {
      id: 0,
      slug: "boissons-chaudes-et-sans-alcool",
      title: "drinks.hot-drinks.title",
      logo: "drinks/hot-drinks",
      inverted: false,
      size: 1,
      cards: [
        {
          type: "table",
          title: "drinks.hot-drinks.0.title",
          rows: [
            {
              col1: "drinks.hot-drinks.0.r0c1",
              col2: "2€"
            },
            {
              col1: "drinks.hot-drinks.0.r1c1",
              col2: "2.20€ "
            },
            {
              col1: "drinks.hot-drinks.0.r2c1",
              col2: "3.80€ "
            },
            {
              col1: "drinks.hot-drinks.0.r3c1",
              col2: "3.80€ "
            },
            {
              col1: "drinks.hot-drinks.0.r4c1",
              col2: "3.50€ ",
            },
            {
              col1: "drinks.hot-drinks.0.r5c1",
              col2: "4.50€ ",
            },
            {
              col1: "drinks.hot-drinks.0.r6c1",
              col2: "3.80€ "
            },
            {
              col1: "drinks.hot-drinks.0.r7c1",
              col2: "5€ "
            },
            {
              col1: "drinks.hot-drinks.0.r8c1",
              col2: "6€"
            },
            {
              col1: "drinks.hot-drinks.0.r9c1",
              col2: "5€ "
            },
            {
              col1: "drinks.hot-drinks.0.r10c1",
              col2: "4.80€"
            },
            {
              col1: "drinks.hot-drinks.0.r11c1",
              col2: "9€"
            },
            {
              col1: "drinks.hot-drinks.0.r12c1",
              col2: "4.80€"
            },
            {
              col1: "drinks.hot-drinks.0.r13c1",
              col2: "7.50€"
            },
            {
              col1: "drinks.hot-drinks.0.r14c1",
              col2: "6€"
            }
          ]
        },
        {
          type: "table",
          title: "drinks.hot-drinks.1.title",
          rows: [
            {
              col1: "drinks.hot-drinks.1.r0c1",
              col2: "3.80€"
            },
            {
              col1: "drinks.hot-drinks.1.r1c1",
              col2: "3.80€"
            },
            {
              col1: "drinks.hot-drinks.1.r2c1",
              col2: "3.90€"
            },
            {
              col1: "drinks.hot-drinks.1.r3c1",
              col2: "3.90€",
              desc: "drinks.hot-drinks.1.r3desc"
            },
            {
              col1: "drinks.hot-drinks.1.r4c1",
              col2: "3.90€",
              desc: "drinks.hot-drinks.1.r4desc"
            },
            {
              col1: "drinks.hot-drinks.1.r5c1",
              col2: "3.90€"
            },
            {
              col1: "drinks.hot-drinks.1.r6c1",
              col2: "3.90€"
            },
            {
              col1: "drinks.hot-drinks.1.r7c1",
              col2: "3.90€"
            },
            {
              col1: "drinks.hot-drinks.1.r8c1",
              col2: "3.90€"
            },
            {
              col1: "drinks.hot-drinks.1.r9c1",
              col2: "4.20€"
            },
            {
              col1: "drinks.hot-drinks.1.r10c1",
              col2: "2.90€",
              desc: "drinks.hot-drinks.1.r10desc"
            },
            {
              col1: "drinks.hot-drinks.1.r11c1",
              col2: "4.50€"
            },
            {
              col1: "drinks.hot-drinks.1.r12c1",
              col2: "6€"
            },
            {
              col1: "drinks.hot-drinks.1.r13c1",
              col2: "6€"
            },
            {
              col1: "drinks.hot-drinks.1.r14c1",
              col2: "6.80€",
              desc: "drinks.hot-drinks.1.r14desc"
            },
            {
              col1: "drinks.hot-drinks.1.r15c1",
              col2: "6.80€",
              desc: "drinks.hot-drinks.1.r15desc"
            }
          ]
        }
      ]
    },
    {
      id: 1,
      slug: "aperitif-et-digestifs",
      title: "drinks.aperitif-liqueur.title",
      logo: "drinks/aperitif-liqueur",
      inverted: false,
      size: 1,
      cards: [
        {
          type: "table",
          title: "drinks.aperitif-liqueur.0.title",
          rows: [
            {
              col1: "drinks.aperitif-liqueur.0.r0c1",
              col2: "6€"
            },
            {
              col1: "drinks.aperitif-liqueur.0.r1c1",
              col2: "7€"
            },
            {
              col1: "drinks.aperitif-liqueur.0.r2c1",
              col2: "8€"
            },
            {
              col1: "drinks.aperitif-liqueur.0.r3c1",
              col2: "7€"
            },
            {
              col1: "drinks.aperitif-liqueur.0.r4c1",
              col2: "6€"
            },
            {
              col1: "drinks.aperitif-liqueur.0.r5c1",
              col2: "6€"
            },
            {
              col1: "drinks.aperitif-liqueur.0.r6c1",
              col2: "6€"
            },
            {
              col1: "drinks.aperitif-liqueur.0.r7c1",
              col2: "6€"
            },
            {
              col1: "drinks.aperitif-liqueur.0.r8c1",
              col2: "2€"
            },
            {
              col1: "drinks.aperitif-liqueur.0.r9c1",
              col2: "0.30€"
            },
            {
              col1: "drinks.aperitif-liqueur.0.r10c1",
              col2: "4€"
            },
            {
              col1: "drinks.aperitif-liqueur.0.r11c1",
              col2: "5€"
            },
            {
              col1: "drinks.aperitif-liqueur.0.r12c1",
              col2: "7.50€"
            },
            {
              col1: "drinks.aperitif-liqueur.0.r13c1",
              col2: "10€"
            },
            {
              col1: "drinks.aperitif-liqueur.0.r11c1",
              col2: "5€"
            },
            {
              col1: "drinks.aperitif-liqueur.0.r15c1",
              col2: "5€"
            },
            {
              col1: "drinks.aperitif-liqueur.0.r16c1",
              col2: "5€"
            },
            {
              col1: "drinks.aperitif-liqueur.0.r17c1",
              col2: "4.50€",
              desc: "drinks.aperitif-liqueur.0.r17desc"
            },
            {
              col1: "drinks.aperitif-liqueur.0.r18c1",
              col2: "9.50€",
              desc: "drinks.aperitif-liqueur.0.r18desc"
            },
            {
              col1: "drinks.aperitif-liqueur.0.r19c1",
              col2: "9.50€",
              desc: "drinks.aperitif-liqueur.0.r19desc"
            },
            {
              col1: "drinks.aperitif-liqueur.0.r20c1",
              col2: "9.50€",
              desc: "drinks.aperitif-liqueur.0.r20desc"
            },
            {
              col1: "drinks.aperitif-liqueur.0.r21c1",
              col2: "9.50€",
              desc: "drinks.aperitif-liqueur.0.r21desc"
            },
            {
            col1: "drinks.aperitif-liqueur.0.r22c1",
            col2: "9.50€",
            desc: "drinks.aperitif-liqueur.0.r22desc"
            },
            {
            col1: "drinks.aperitif-liqueur.0.r23c1",
           col2: "9.50€",
            desc: "drinks.aperitif-liqueur.0.r23desc"
            }
          ]
        },
        {
          type: "table",
          title: "drinks.aperitif-liqueur.1.title",
          rows: [
            {
              col1: "drinks.aperitif-liqueur.1.r0c1",
              col2: "7.50€"
            },
            {
              col1: "drinks.aperitif-liqueur.1.r1c1",
              col2: "7.50€"
            },
            {
              col1: "drinks.aperitif-liqueur.1.r2c1",
              col2: "7.50€"
            },
            {
              col1: "drinks.aperitif-liqueur.1.r3c1",
              col2: "7.50€"
            },
            {
              col1: "drinks.aperitif-liqueur.1.r4c1",
              col2: "7.50€"
            },
            {
              col1: "drinks.aperitif-liqueur.1.r5c1",
              col2: "7.50€"
            },
            {
              col1: "drinks.aperitif-liqueur.1.r6c1",
              col2: "7.50€"
            },
            {
              col1: "drinks.aperitif-liqueur.1.r7c1",
              col2: "7.50€"
            },
            {
              col1: "drinks.aperitif-liqueur.1.r8c1",
              col2: "8.50€"
            },
            {
              col1: "drinks.aperitif-liqueur.1.r9c1",
              col2: "8.50€"
            },
            {
              col1: "drinks.aperitif-liqueur.1.r10c1",
              col2: "8.50€",
            },
            {
              col1: "drinks.aperitif-liqueur.1.r11c1",
              col2: "7.50€",
            }   
          ]
        }
      ]
    },
    {
      id: 2,
      slug: "bieres",
      title: "drinks.beers.title",
      logo: "drinks/beers",
      inverted: false,
      size: 1,
      cards: [
        {
          type: "table",
          title: "drinks.beers.0.title",
          head: {
            col1: "drinks.beers.0.hc1",
            col2: "25cl",
            col3: "50cl"
          },
          rows: [
            {
              col1: "drinks.beers.0.r0c1",
              col2: "4€",
              col3: "7.80€"
            },
            {
              col1: "drinks.beers.0.r1c1",
              col2: "5€",
              col3: "9.80€"
            },
            {
              col1: "drinks.beers.0.r2c1",
              col2: "5.50€",
              col3: "10.80€"
            },
            {
              col1: "drinks.beers.0.r3c1",
              col2: "4€",
              col3: "7.80€"
            },
            {
              col1: "drinks.beers.0.r4c1",
              col2: "4.20€",
              col3: "8€"
            },
            {
              col1: "drinks.beers.0.r5c1",
              col2: "5€",
              col3: "9.80€"
            }
          ]
        },
        {
          type: "table",
          head: {
            col1: "drinks.beers.1.hc1",
            col2: "33cl"
          },
          rows: [
            {
              col1: "drinks.beers.1.r0c1",
              col2: "7€"
            },
            {
              col1: "drinks.beers.1.r2c1",
              col2: "7€"
            },
            {
              col1: "drinks.beers.1.r3c1",
              col2: "7€"
            },
            {
              col1: "drinks.beers.1.r4c1",
              col2: "8€"
            },
            {
              col1: "drinks.beers.1.r5c1",
              col2: "8€"
            },
            {
              col1: "drinks.beers.1.r6c1",
              col2: "8€"
            },
            {
              col1: "drinks.beers.1.r7c1",
              col2: "8€ "
            },
            {
              col1: "drinks.beers.1.r8c1",
              col2: "8€"
            },
            {
              col1: "drinks.beers.1.r9c1",
              col2: "7€"
            }
          ]
        }
      ]
    },
    {
      id: 3,
      slug: "vins",
      title: "drinks.wines.title",
      logo: "drinks/wines",
      inverted: false,
      size: 1,
      cards: [
        {
          type: "table",
          title: "drinks.wines.0.title",
          rows: [
            {
              col1: "drinks.wines.0.r0c1",
              col2: "12cl 5€",
              col3: "75cl 25€"
            },
            {
              col1: "drinks.wines.0.r1c1",
              col3: "75cl 29€"
            },
            {
              col1: "drinks.wines.0.r2c1",
              col3: "75cl 44€"
            },
            {
              col1: "drinks.wines.0.r3c1",
              col2: "12cl 8€",
              col3: "75cl 44€"
            },
            {
              col1: "drinks.wines.0.r4c1",
              col2: "37,5cl 18€",
              col3: "75cl 29€"
            },
            {
              col1: "drinks.wines.0.r5c1",
              col3: "75cl 23€"
            },
            {
              col1: "drinks.wines.0.r6c1",
              col2: "12cl 3.50€",
              col3: "pichet 50cl 8€"
            }
          ]
        },
        {
          type: "table",
          title: "drinks.wines.1.title",
          rows: [
            {
              col1: "drinks.wines.1.r0c1",
              col2: "50cl 18€",
              col3: "75cl 21€"
            },
            {
              col1: "drinks.wines.1.r1c1",
              col3: "75cl 28€"
            },
            {
              col1: "drinks.wines.1.r2c1",
              col3: "75cl 24€"
            },
            {
              col1: "drinks.wines.1.r3c1",
              col2: "12cl 7.50€",
              col3: "75cl 42€"
            },
            {
              col1: "drinks.wines.1.r4c1",
              col2: "37.5cl 19€",
              col3: "75cl 19€"
            },
            {
              col1: "drinks.wines.1.r5c1",
              col3: "75cl 25€"
            },
            {
              col1: "drinks.wines.1.r6c1",
              col3: "75cl 25€"
            },
            {
              col1: "drinks.wines.1.r7c1",
              col3: "75cl 29€"
            },
            {
              col1: "drinks.wines.1.r8c1",
              col2: "12cl 3.50€",
              col3: "pichet 50cl 8€"
            },
            {
              col1: "drinks.wines.1.r9c1",
              col3: "75cl 19€"
            }
          ]
        },
        {
          type: "table",
          title: "drinks.wines.2.title",
          rows: [
            {
              col1: "drinks.wines.2.r0c1",
              col2: "12cl 4.50€",
              col3: "75cl 22€"
            },
            {
              col1: "drinks.wines.2.r1c1",
              col2: "12cl 3.50€",
              col3: "pichet 50cl 8€"
            }
          ]
        },
        {
          type: "table",
          title: "drinks.wines.3.title",
          rows: [
            {
              col1: "drinks.wines.3.r0c1",
              col2: "12cl 4.50€",
              col3: "75cl 25€"
            },
            {
              col1: "drinks.wines.3.r1c1",
              col3: "75cl 59€"
            },
            {
              col1: "drinks.wines.3.r2c1",
              col2: "37.5cl 44€",
              col3: "75cl 74€"
            }
          ]
        }
      ]
    }
  ]
};

export default state;
