<template>
  <b-modal ref="newyear" id="newyear" hide-footer size="md" centered class="text-center plop text-gray">
    <b-btn-close slot="modal-header" @click="hideModal" class="text-white" style="opacity: .8">×</b-btn-close>
    <h3 class="text-primary">{{ $t('newyear.0') }}<br/>
      <small>{{ $t('newyear.1') }}</small>
    </h3>
    <p>
      {{ $t('newyear.2') }}<br/>
      <small class="d-none d-md-inline">Cocktail pétillant de La ferme d'Oz</small>
    </p>
    <p>{{ $t('newyear.3') }}<br/>
      <small class="d-none d-md-inline">Bourgogne aligoté, Domaine Buissonier</small>
    </p>
    <p>{{ $t('newyear.4') }}<br/>
      <small class="d-none d-md-inline">Crozes-Hermitage, Cave de Tain</small>
    </p>
    <p>{{ $t('newyear.5') }}</p>
    <p>{{ $t('newyear.6') }}<br/>
      <small class="d-none d-md-inline">Rasteau, Domaine des Escaravailles</small>
    </p>
    <p>{{ $t('newyear.7') }}<br/>
      <small class="d-none d-md-inline">Champagne Veuve Pelletier</small>
    </p>
    <p>{{ $t('newyear.8') }}</p>

    <h4>
      <small>{{ $t('newyear.11') }}</small>
    </h4>
    <p>{{ $t('newyear.12') }}</p>
    <p>{{ $t('newyear.13') }}</p>
    <p>{{ $t('newyear.14') }}</p>
    <p>{{ $t('newyear.15') }}</p>
  </b-modal>
</template>

<script>
export default {
  mounted: function() {
    if (Date.now() < new Date(2025, 0, 1)) {
      setTimeout(() => {
        this.showModal();
      }, 2000);
    }
  },
  methods: {
    hideModal() {
      this.$refs.newyear.hide();
    },
    showModal() {
      this.$refs.newyear.show();
    }
  }
};
</script>

<style lang="postcss">
.plop header {
  background-image: url("../assets/images/newyear.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  height: 8rem;
}
p {
  margin-bottom: 0.5rem;
}
</style>
